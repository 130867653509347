import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from '@/components/login/login.component';
import { LoginGuard } from '@/guards/login.guard';
import { NotFoundComponent } from '@/components/not-found/not-found.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'owners',
    loadChildren: () =>
      import('./owners/owners.module').then((m) => m.OwnersModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'business',
    loadChildren: () =>
      import('./business/business.module').then((m) => m.BusinessModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'claims',
    loadChildren: () =>
      import('./claim-requests/claim-requests.module').then(
        (m) => m.ClaimRequestsModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'account-types',
    loadChildren: () =>
      import('./account-types/account-types.module').then(
        (m) => m.AccountTypesModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'tags',
    loadChildren: () => import('./tags/tags.module').then((m) => m.TagsModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'reviews',
    loadChildren: () =>
      import('./reviews/reviews.module').then((m) => m.ReviewsModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'manage',
    loadChildren: () =>
      import('./manage/mange.module').then((m) => m.MangeModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'translation',
    loadChildren: () =>
      import('./translation/translation.module').then(
        (m) => m.TranslationModule
      ),
    canActivate: [LoginGuard],
  },
  {
    path: 'jobs',
    loadChildren: () => import('./jobs/jobs.module').then((m) => m.JobsModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'pages',
    loadChildren: () =>
      import('./pages/pages.module').then((m) => m.PagesModule),
    canActivate: [LoginGuard],
  },
  {
    path: 'profiles',
    loadChildren: () =>
      import('./profiles/profiles.module').then((m) => m.ProfilesModule),
    canActivate: [LoginGuard],
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
